import React, { useState, useEffect } from 'react';
import axios from "axios";
import { serverEndpoint } from "../../../util/config";
import Spinner from "../../../components/Spinner";
import { useDispatch } from 'react-redux';
import { useNavigate } from "react-router-dom";
import { CButton, CCard, CCardBody, CCardText, CCardTitle, CRow, CCol, CModal, CModalHeader, CModalBody, CModalFooter, CModalTitle, CCardSubtitle } from '@coreui/react';
import { Badge } from 'react-bootstrap';
import { FaCircleCheck } from "react-icons/fa6";

const formatDate = (dateString) => {
    const options = { year: 'numeric', month: 'short', day: 'numeric' };
    return new Date(dateString).toLocaleDateString('en-US', options);
};

const getPlan = async (customerID) => {
    try {
        const subscriptionResponse = await axios.post(`${serverEndpoint}/payment/check-subscription`,
            { stripe_customer_id: customerID },
            { withCredentials: true });
        const subscriptionData = subscriptionResponse.data;
        const productID = subscriptionData.subscription.plan.product;

        const productResponse = await axios.post(`${serverEndpoint}/payment/check-products`,
            { productID: productID },
            { withCredentials: true });

        const productData = productResponse.data;

        const name = productData.name;
        const price = `£${(subscriptionData.subscription.plan.amount) / 100}`;
        const timeline = productData.description;
        const endDate = formatDate(subscriptionData.subscription.current_period_end * 1000);

        return {
            subscriptionId: subscriptionData.subscription.id,
            planTitle: name,
            price: price,
            timeline: timeline,
            endDate: endDate
        };
    } catch (error) {
        console.log(error);
        return null;
    }
};

const CancelSubscriptionBox = ({email, customerID, plan}) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [showConfirmation, setShowConfirmation] = useState(false);
    const handleCancelClick = () => {
        setShowConfirmation(true);
    };

    const handleConfirmCancel = () => {
        axios.post(`${serverEndpoint}/payment/cancel-subscription`,
            { email: email, customerID: customerID, subscriptionId: plan.subscriptionId },
            { withCredentials: true })
            .then(() => {
                // After successful subscription cancellation, delete the account
                return axios.post(`${serverEndpoint}/auth/delete-account`, { email : email }, { withCredentials: true });
            })
            .then(() => {
                dispatch({ type: 'toggleFetchTrigger' });
                navigate('/logout');
            })
            .catch((error) => {
                console.log(error);
            });
        setShowConfirmation(false);
    };

    const handleCancelConfirmation = () => {
        setShowConfirmation(false);
    };

    return (
        <CCard>
            <button className='btn btn-danger text-white btn-sm' onClick={handleCancelClick}>
                Cancel Subscription
            </button>

            <CModal visible={showConfirmation} onClose={handleCancelConfirmation}>
                <CModalHeader closeButton>
                    <CModalTitle>Confirm Cancellation</CModalTitle>

                </CModalHeader>
                <CModalBody>
                    Are you sure you want to cancel your subscription? Your account will be deleted.
                </CModalBody>
                <CModalFooter>
                    <CButton color="secondary" onClick={handleCancelConfirmation}>No</CButton>
                    <CButton color="primary" onClick={handleConfirmCancel}>Yes</CButton>
                </CModalFooter>
            </CModal>
        </CCard>
    );
};

function ManageSubscription({ userDetails }) {
    const [plan, setPlan] = useState(null);
    const [loading, setLoading] = useState(true);
    const navigate = useNavigate();

    const handleClick = () => {
        navigate("/change-subscription");
    };

    useEffect(() => {
        const fetchPlan = async () => {
            const planData = await getPlan(userDetails.stripeObject?.customer_id);
            setPlan(planData);
            setLoading(false);
        };

        fetchPlan();
    }, [userDetails.username]);

    if (loading) return <Spinner />;

    return (
        <CCard>
            <CCardBody>
                <div className="d-flex justify-content-between align-items-center">
                    <div>
                        <CCardTitle>Plan & Billing</CCardTitle>
                        <CCardText>Manage your plan and payments</CCardText>
                    </div>
                    <div>
                        {plan && (
                            <CancelSubscriptionBox
                                plan={plan}
                                email={userDetails.username}
                                customerID={userDetails.stripeObject?.customer_id}
                            />
                        )}
                    </div>
                </div>
            </CCardBody>
            <hr></hr>
            <CCardBody>
                <div className="d-flex justify-content-between align-items-center mb-2">
                    <div>
                        <CCardSubtitle>Current Plan</CCardSubtitle>
                    </div>
                    <div>
                        <CCard>
                            <button className='btn btn-primary btn-sm' onClick={handleClick}>
                                Change Plan
                            </button>
                        </CCard>
                    </div>
                </div>
                <div>
                    <CRow>
                        <CCol xs={12} sm={6}>
                            <CCard>
                                <CCardBody>
                                    <CCardText className="d-flex justify-content-between align-items-center">
                                        {plan && plan.planTitle}
                                        <Badge pill bg="success" className="text-white">
                                            <FaCircleCheck className='pb-0'/> Active
                                        </Badge>
                                    </CCardText>
                                    <CCardTitle>
                                        {plan && plan.price}{plan && plan.timeline === 'Monthly Plan' ? '/monthly' : '/yearly'}
                                    </CCardTitle>
                                </CCardBody>
                            </CCard>
                        </CCol>
                        <CCol xs={12} sm={6}>
                            <CCard>
                                <CCardBody>
                                    <CCardText>Renew at</CCardText>
                                    <CCardTitle>
                                        {plan.endDate}
                                    </CCardTitle>
                                </CCardBody>
                            </CCard>
                        </CCol>
                    </CRow>
                </div>
            </CCardBody>
        </CCard>
    );
}

export default ManageSubscription;
