import React, { useState, useRef } from 'react';
import { serverEndpoint } from "../../util/config";
import { CCard, CCardBody, CCardHeader, CButton, CForm, CFormInput, CRow, CCol, CSpinner } from '@coreui/react';
import axios from 'axios';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { DataGrid, GridToolbarQuickFilter } from '@mui/x-data-grid';
import { Box, Link } from '@mui/material';

const StorefrontSearch = () => {
  const [storefronts, setStorefronts] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');
  const [loading, setLoading] = useState(false);
  const [products, setProducts] = useState(null);
  const [productsLoading, setProductsLoading] = useState(false);
  const productTableRef = useRef(null);

  const theme = createTheme({
    components: {
      MuiDataGrid: {
        styleOverrides: {
          root: {
            border: 'none',
            overflowY: 'hidden',
          },
          columnHeaders: {
            fontWeight: 'bold',
            '& .MuiDataGrid-columnHeaderTitle': {
              fontWeight: 'bold',
            },
          },
        },
      },
    },
  });

  const columns = [
    {
      field: 'title',
      headerName: 'Title',
      width: 250,
      flex: 1,
      renderCell: (params) => (
        <>
          <img
            src={params.row.image || 'https://via.placeholder.com/100'}
            alt={params.row.title}
            style={{ maxHeight: '50px' }}
            className="rounded-1 mx-1"
          /> {params.row.title}
        </>
      )
    },
    {
      field: 'rating',
      headerName: 'Rating',
      width: 180
    },
    {
      field: 'price',
      headerName: 'Price',
      width: 100
    },
    {
      field: 'sales_count',
      headerName: 'Sales Count',
      width: 100
    },
    {
      field: 'link',
      headerName: 'Product Link',
      width: 200,
      renderCell: (params) => (
        <Link href={params.value} target="_blank" rel="noopener">
          View on Amazon
        </Link>
      )
    }
  ];

  const handleSearch = async () => {
    setStorefronts(null);
    setProducts(null);
    setLoading(true);
    try {
      const response = await axios.get(`${serverEndpoint}/storefronts/get-storefronts/${searchTerm}`, { withCredentials: true });
      console.log(response.data)
      setStorefronts(response.data);
    } catch (error) {
      console.error("Error fetching storefront:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    handleSearch();
  };

  const fetchProducts = async (sellerId) => {
    setProductsLoading(true); // Show spinner while loading
    setProducts(null);
    try {
      const response = await axios.get(`${serverEndpoint}/products/get-products/${sellerId}`, { withCredentials: true });
      setProducts(response.data);

      if (productTableRef.current) {
        productTableRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
      }
    } catch (error) {
      console.error("Error fetching products:", error);
    } finally {
      setProductsLoading(false);
    }
  };

  return (
    <div className="container">
      <CCard>
        <CCardHeader>Search Storefronts</CCardHeader>
        <CCardBody>
          <CForm onSubmit={handleSubmit}>
            <CRow className="mb-3">
              <CCol xs="12">
                <CFormInput
                  type="text"
                  placeholder="Enter the Storefront ID or Nickname, or leave it empty to fetch all storefronts."
                  value={searchTerm}
                  onChange={(e) => setSearchTerm(e.target.value)}
                />
              </CCol>
            </CRow>
            <CButton type="submit" color="primary">{loading ? <CSpinner size="sm" /> : 'Search'}</CButton>
          </CForm>

          <hr />

          {(Array.isArray(storefronts) && storefronts.length > 0) || (storefronts && !Array.isArray(storefronts)) ? (
            <CCard>
              <CCardHeader>Storefront Details</CCardHeader>
              {Array.isArray(storefronts) ? storefronts.map((sf, index) => (
                <div key={index}>
                  <CRow className="align-items-center ps-3">
                    <CCol xs="12" md="3"><p><strong>Storefront ID:</strong> {sf.seller_id}</p></CCol>
                    <CCol xs="12" md="3"><p><strong>Nickname:</strong> {sf.nick_name}</p></CCol>
                    <CCol xs="12" md="3"><p><strong>Marketplace ID:</strong> {sf.marketplace_id}</p></CCol>
                    <CCol xs="12" md="3" className="text-md-end pe-4">
                      <CButton color="primary" onClick={() => fetchProducts(sf.seller_id)} disabled={productsLoading}>View Products</CButton>
                    </CCol>
                  </CRow>
                </div>
              )) : (
                <CRow className="align-items-center ps-3">
                  <CCol xs="12" md="3"><p><strong>Storefront ID:</strong> {storefronts.seller_id}</p></CCol>
                  <CCol xs="12" md="3"><p><strong>Nickname:</strong> {storefronts.nick_name}</p></CCol>
                  <CCol xs="12" md="3"><p><strong>Marketplace ID:</strong> {storefronts.marketplace_id}</p></CCol>
                  <CCol xs="12" md="3" className="text-md-end pe-4">
                    <CButton color="primary" onClick={() => fetchProducts(storefronts.seller_id)} disabled={productsLoading}>View Products</CButton>
                  </CCol>
                </CRow>
              )}
            </CCard>
          ) : (
            !loading && <p className="mt-4" style={{ color: '#999', textAlign: 'center' }}>No storefront found.</p>
          )}

          <hr />

          {/* Product Details */}
          {productsLoading ? (
            <div className="text-center">
              <CSpinner color="primary" />
            </div>
          ) : products && (
            <CCard ref={productTableRef}>
              <CCardHeader>Products</CCardHeader>
              <hr className="text-muted" />
              <div style={{ display: 'flex', flexDirection: 'column' }}>
                <ThemeProvider theme={theme}>
                  <Box>
                    <DataGrid
                      initialState={{
                        columns: {
                          columnVisibilityModel: {
                            item_id: false
                          },
                        },
                        pagination: { paginationModel: { pageSize: 20 } },
                      }}
                      rows={products}
                      getRowId={(row) => row.sk}
                      columns={columns}
                      disableRowSelectionOnClick
                      slots={{
                        toolbar: GridToolbarQuickFilter,
                      }}
                      slotProps={{
                        toolbar: {
                          showQuickFilter: true,
                        },
                      }}
                      showCellVerticalBorder={true}
                      showColumnVerticalBorder={true}
                      pageSizeOptions={[20, 50, 100]}
                    />
                  </Box>
                </ThemeProvider>
              </div>
            </CCard>

          )}
        </CCardBody>
      </CCard>
    </div>
  );
};

export default StorefrontSearch;
