import { Navigate, Route, Routes, useNavigate } from "react-router-dom";
import Signin from "./pages/auth/Signin";
import Signup from "./pages/auth/Signup";
import { useCallback, useEffect, useState } from "react";
import axios from "axios";
import Dashboard from "./pages/dashboard/Dashboard";
import Logout from "./pages/auth/Logout";
import Spinner from "./components/Spinner";
import ForgotPassword from "./pages/auth/ForgotPassword";
import MultiFactorAuthenticationSetting from "./pages/dashboard/settings/MultiFactorAuthenticationSetting";
import { serverEndpoint } from "./util/config";
import AppLayout from "./layout/AppLayout";
import './scss/style.scss'
import UnauthorizedAccess from "./pages/UnauthorizedAccess";
import StorefrontManager from './pages/storefront/StorefrontManager';
import StorefrontSearch from "./pages/storefront/StorefrontSearch";
import PaymentSuccess from "./pages/payments/PaymentSuccess";
import ManageSubscriptions from "./pages/dashboard/payment/ManageSubscription";
import ChangeSubscription from "./pages/dashboard/payment/ChangeSubscription";
import PaymentPlans from "./pages/payments/PaymentPlans";
import { useDispatch, useSelector } from "react-redux";

function App() {
    const [loading, setLoading] = useState(true);
    const navigate = useNavigate();

    const dispatch = useDispatch();
    const triggerFetch = useSelector((state) => state.triggerFetch);
    const userDetails = useSelector((state) => state.userDetails);

    const updateUserDetails = (newUserDetails) => {
        dispatch({ type: 'setUserDetails', userDetails: newUserDetails });
    };

    // Using useEffect to navigate after userDetails are updated
    useEffect(() => {
        if (userDetails?.stripeObject?.payment_required) {
            navigate('/subscribe');
        }
    }, [userDetails, navigate]); // Runs every time userDetails or navigate changes

    const isUserLoggedIn = useCallback(() => {
        setLoading(true);
        axios.get(`${serverEndpoint}/authorize/is-authorized-user`, { withCredentials: true })
            .then(response => {
                const user = response.data.user;
                dispatch({ type: 'setUserDetails', userDetails: user });
                setLoading(false);
            })
            .catch(error => {
                // Assuming the user is not logged in!
                setLoading(false);
            });
    }, [dispatch]);

    useEffect(() => {
        isUserLoggedIn();
    }, [triggerFetch, isUserLoggedIn]);

    if (loading) {
        return <Spinner />;
    }

    return (
        <>
            <Routes>
                <Route path="/" element={userDetails ? <Navigate to="/dashboard" /> : <Signin updateUserDetails={updateUserDetails} />} />
                <Route path="/signin" element={userDetails ? <Navigate to="/dashboard" /> : <Signin updateUserDetails={updateUserDetails} />} />
                <Route path="/forgot-password" element={userDetails ? <Navigate to="/dashboard" /> : <ForgotPassword />} />
                <Route path="/signup" element={userDetails ? <Navigate to="/dashboard" /> : <Signup />} />
                <Route path="/logout" element={userDetails ? <Logout updateUserDetails={updateUserDetails} /> : <Navigate to="/signin" />} />
                <Route path="/manage-subscription" element={
                    userDetails ? (
                        <AppLayout userDetails={userDetails}>
                            <ManageSubscriptions userDetails={userDetails} />
                        </AppLayout>
                    ) : (
                        <Navigate to="/signin" />
                    )
                }
                />
                <Route path="/change-subscription" element={
                    userDetails ? (
                        <AppLayout userDetails={userDetails}>
                            <ChangeSubscription userDetails={userDetails} />
                        </AppLayout>
                    ) : (
                        <Navigate to="/signin" />
                    )
                }
                />
                <Route path="/dashboard" element={
                    userDetails ? (
                        <AppLayout userDetails={userDetails}>
                            <Dashboard userDetails={userDetails} />
                        </AppLayout>
                    ) : (
                        <Navigate to="/signin" />
                    )
                }
                />
                <Route path="/settings" element={
                    userDetails ? (
                        <AppLayout userDetails={userDetails}>
                            <MultiFactorAuthenticationSetting userDetails={userDetails} />
                        </AppLayout>
                    ) : (
                        <Navigate to="/signin" />
                    )
                }
                />
                <Route path="/unauthorized" element={userDetails ? (
                    <AppLayout userDetails={userDetails}>
                        <UnauthorizedAccess />
                    </AppLayout>
                ) : (
                    <UnauthorizedAccess />
                )} />

                <Route path="/payment-success" element={userDetails ? (
                    <AppLayout userDetails={userDetails}>
                        <PaymentSuccess userDetails={userDetails} />
                    </AppLayout>
                ) : (
                    <PaymentSuccess />
                )} />

                <Route path="/storefront-manager" element={userDetails ? (
                    <AppLayout userDetails={userDetails}>
                        <StorefrontManager userDetails={userDetails} />
                    </AppLayout>
                ) : (
                    <StorefrontManager />
                )} />

                <Route path="/storefront-search" element={userDetails ? (
                    <AppLayout userDetails={userDetails}>
                        <StorefrontSearch userDetails={userDetails} />
                    </AppLayout>
                ) : (
                    <StorefrontSearch />
                )} />

                <Route path="/subscribe" element={userDetails && userDetails.stripeObject?.payment_required ? (
                    <PaymentPlans userDetails={userDetails} isLoggedIn={true} />
                ) : (
                    <Navigate to="/signin" />
                )} />
            </Routes>
        </>
    );
}

export default App;
