import React, { useState, useEffect } from "react";
import { CButton, CCard, CCardBody, CCardHeader, CCardText, CCol, CImage, CLink, CListGroup, CListGroupItem, CRow, CSpinner } from "@coreui/react";
import { serverEndpoint } from "../../util/config";
import axios from "axios";

function Dashboard({ userDetails }) {
  const [loading, setLoading] = useState(true);
  const [unacknowledgedProducts, setUnacknowledgedProducts] = useState([]);

  useEffect(() => {
    const fetchUnacknowledgedProducts = async () => {
      try {
        const response = await axios.get(`${serverEndpoint}/products/get-new-products`, { withCredentials: true });
        if (response.data) {
          setUnacknowledgedProducts(response.data);
        } else {
          console.error("Failed to fetch unacknowledged products");
        }
      } catch (error) {
        console.error("Error fetching unacknowledged products:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchUnacknowledgedProducts();
  }, []);

  const deleteProduct = async (productIds) => {
    try {
      await axios.delete(
        `${serverEndpoint}/products/delete-new-products`,
        {
          data: { productIds }, // Send productIds in the request body
          withCredentials: true,  // Send credentials (cookies)
        }
      );
    } catch (error) {
      console.error("Error deleting product(s):", error);
    }
  };

  const acknowledgeProduct = (productId) => {
    // Remove the product from the local state
    setUnacknowledgedProducts((prevProducts) =>
      prevProducts.filter((product) => product.product_id !== productId)
    );

    // Call the deleteProduct function to remove it from the database
    deleteProduct([productId]);
  };

  return (
    <div className="container">
      <CCard>
        <CCardBody>
          <CCardHeader>Newly Added Products</CCardHeader>
          <p style={{ marginTop: '1rem', marginBottom: '1rem', padding: '1rem', borderRadius: '5px' }}>
            Below are newly added products from all storefronts. Please acknowledge them to remove them from this list.
          </p>

          {loading ? (
            <div className="text-center" style={{ margin: '20px' }}>
              <CSpinner color="primary" />
              <CCardText>Loading products...</CCardText>
            </div>
          ) : unacknowledgedProducts.length === 0 ? (
            <CCardText>All products have been acknowledged!</CCardText>
          ) : (
            <CListGroup>
              {unacknowledgedProducts.map((product) => (
                <CListGroupItem key={product.product_id}>
                  <CRow className="align-items-center">
                    <CCol xs="auto" md="4" className="text-center">
                      <CImage
                        src={product.image || ""}
                        alt={product.title || "No Title"}
                        style={{ width: '100px', height: 'auto' }}
                      />
                    </CCol>
                    <CCol xs="auto" md="5">
                      <div><strong>Price:</strong> {product.price || "N/A"}</div>
                      <div><strong>Rating:</strong> {product.rating || "N/A"}</div>
                      <div><strong>Sales Count:</strong> {product.sales_count || "N/A"}</div>
                      <div><strong>Seller ID:</strong> {product.seller_id || "N/A"}</div>
                      {product.link && (
                        <CLink href={product.link} target="_blank" rel="noopener noreferrer">
                          View on Amazon
                        </CLink>
                      )}
                    </CCol>
                    <CCol xs="auto" md="3" className="text-end">
                      <CButton color="primary" size="sm" onClick={() => acknowledgeProduct(product.product_id)}>
                        Acknowledge
                      </CButton>
                    </CCol>
                  </CRow>
                </CListGroupItem>
              ))}
            </CListGroup>
          )}
        </CCardBody>
      </CCard>
    </div>
  );
}

export default Dashboard;
