
import React, { useState } from 'react';
import axios from 'axios';
import { serverEndpoint } from "../../util/config";
import { CButton, CCard, CCardBody, CCardHeader, CCol, CForm, CFormInput, CFormLabel, CRow } from '@coreui/react';

const StorefrontManager = ({ userDetails }) => {
  const [storefrontId, setStorefrontId] = useState('');
  const [nickname, setNickname] = useState('');
  const [error, setError] = useState(null);
  const [successMessage, setSuccessMessage] = useState(null);
  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);

  const isAlphanumeric = (str) => /^[a-zA-Z0-9]+$/.test(str);

  const handleAddStorefront = async (e) => {
    e.preventDefault();
    setError(null);
    setSuccessMessage(null);

    let isValid = true;
    let newErrors = {};
    // Check if both fields are filled out
    if (!storefrontId) {
      newErrors.storefrontId = "Please provide a storefront id";
      isValid = false;
    }

    // Validate if both inputs are alphanumeric
    if (!isAlphanumeric(storefrontId)) {
      newErrors.storefrontId = "Storefront ID must only contain alphabets and numbers";
      isValid = false;
    }

    if (nickname.length > 30) {
      newErrors.nickname = "Nickname must be less than 30 characters";
      isValid = false;
    }

    if (!isValid) {
      setErrors(newErrors);
      return;
    } else {
      setErrors({});
    }

    setLoading(true);
    try {
      await axios.post(
        `${serverEndpoint}/storefronts/create-storefront`,
        { seller_id: storefrontId, nick_name: nickname, marketplace_id: 'A1F83G8C2ARO7P' },
        { withCredentials: true }
      );

      setStorefrontId('');
      setNickname('');
      setSuccessMessage('Storefront successfully added!');
      setTimeout(() => setSuccessMessage(''), 3000);

    } catch (err) {
      setError('Failed to add storefront. Please try again.');
      console.error("Error adding storefronts:", err.message);
    } finally {
      setLoading(false);
    }

  };

  return (
    <CCard>
      <CCardHeader>
        Add Storefront
        <p className='text-muted mb-1'>
          It may take 10-15 minutes for new products to appear if the Storefront ID is not in our database. You will receive an email notification once the products are available.
        </p>
      </CCardHeader>
      <CCardBody>
        
        {successMessage && <p className="text-success mt-2">{successMessage}</p>}
        {error && <p className="text-danger mt-2">{error}</p>}
        <form onSubmit={handleAddStorefront}>
          <div className="mb-3">
            <label className="mb-2 text-muted" htmlFor="storefrontId">
              Storefront ID
            </label>
            <input id="storefrontId" type="text" className={errors.storefrontId ? 'form-control is-invalid' : 'form-control'}
              name="storefrontId" onChange={(e) => setStorefrontId(e.target.value)} value={storefrontId} placeholder="Enter Storefront ID" autoFocus
            />
            <div className="invalid-feedback">{errors?.storefrontId}</div>
          </div>

          <div className="mb-3">
            <label className="mb-2 text-muted" htmlFor="nickname">
              Nickname
            </label>
            <input id="nickname" type="text" className={errors.nickname ? 'form-control is-invalid' : 'form-control'}
              name="nickname" onChange={(e) => setNickname(e.target.value)} value={nickname} placeholder="Enter Nickname"
            />
            <div className="invalid-feedback">{errors?.nickname}</div>
          </div>

          <div className="align-items-center d-flex">
            {loading && (
              <button className="btn btn-primary ms-auto" type="button" disabled="">
                <span
                  className="spinner-border spinner-border-sm"
                  role="status"
                  aria-hidden="true"
                />
              </button>
            )}

            {!loading && (
              <button className="btn btn-primary ms-auto" type="submit">
                Add
              </button>
            )}
          </div>
        </form>

      </CCardBody>
    </CCard>
  );
};

export default StorefrontManager;
