import React from 'react'
import { CFooter } from '@coreui/react'
import { Link } from 'react-router-dom';

function AppFooter() {
    return (
        <CFooter className="px-4">
            <div>
                <span className="ms-1">JBL Storefront Stalker &copy; 2024</span>
            </div>
            <div className="ms-auto">
                <span className="me-1">Powered by </span>
                <Link to="https://jblsoftware.com/" target="_blank">
                    JBL Softwares
                </Link>
            </div>
        </CFooter>
    )
}

export default AppFooter;
