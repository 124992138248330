import { FaRegCheckCircle } from "react-icons/fa";
import { Link } from "react-router-dom";

function PaymentSuccess({ userDetails }) {
    return (
        <div className="row justify-content-center mt-7">
            <div className="col-lg-5 text-center">
                <div className="card mt-5">
                    <div className="card-body py-5 px-lg-5">
                        <FaRegCheckCircle className="text-success" size={80} />
                        {userDetails && (
                            <p className="mt-4 mb-1">
                                Your payment has been processed successfully. You can manage your
                                subscription from Settings.
                            </p>
                        )}

                        {!userDetails && (
                            <p className="mt-4 mb-1">
                                Your payment has been processed successfully. 
                                You can now access your account using <Link className="btn-link" to="/signin">sign in</Link>.
                            </p>
                        )}

                    </div>
                </div>
            </div>
        </div>
    );
}

export default PaymentSuccess;