function UnauthorizedAccess() {
    return (
        <div className="container">
            <div className="row">
                <div className="col text-center">
                    <h2>Unauthorized Access</h2>
                    <p className="mt-2">
                        You do not have the necessary permissions to view this page.
                        <br/>
                        If you believe this is an error, please contact the system administrator or log in with the correct account.
                    </p>
                </div>
            </div>
        </div>
    );
}

export default UnauthorizedAccess;