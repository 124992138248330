import { useState } from "react";
import { Link } from "react-router-dom";
import axios from 'axios';
import { serverEndpoint } from "../../util/config";
import VerifyEmail from "./VerifyEmail";
import { TbHexagonLetterJ } from "react-icons/tb";

function Signup() {
    const [loading, setLoading] = useState(false);
    const [verifyEmailDetails, setVerifyEmailDetails] = useState(null);
    const [formData, setFormData] = useState({
        email: '',
        password: '',
        phone: '',
        business_name: ''
    });
    const [errors, setErrors] = useState({
        passwordErrors: [],
        business_name: '',
        email: '',
        phone: '',
        message: ''
    });

    const validatePassword = (password) => {
        const requirements = [
            { regex: /[a-z]/, message: "Must contain at least one lowercase letter" },
            { regex: /[A-Z]/, message: "Must contain at least one uppercase letter" },
            { regex: /\d/, message: "Must contain at least one number" },
            { regex: /[\s!@#$%^&*(),.?":{}|<>]/, message: "Must contain at least one special character or space" },
            { regex: /^.{8,}$/, message: "Must be at least 8 characters long" },
            { regex: /^(?!.*\s{2,}).*$/, message: "Must not contain consecutive spaces" }
        ];

        return requirements
            .filter(req => !req.regex.test(password))
            .map(req => req.message);
    };

    const validateForm = () => {
        let isValid = true;
        const newErrors = {
            passwordErrors: validatePassword(formData.password),
            business_name: '',
            email: '',
            phone: '',
            message: ''
        };

        if (formData.business_name.length < 3) {
            newErrors.business_name = 'Please provide a valid Business Name. Must be atleast 3 characters';
            isValid = false;
        }

        const phoneRegex = /^\+[1-9]{1}[0-9]{7,14}$/;
        if (!phoneRegex.test(formData.phone)) {
            newErrors.phone = 'Please provide a valid Phone';
            isValid = false;
        }

        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (!emailRegex.test(formData.email)) {
            newErrors.email = 'Please provide a valid Email';
            isValid = false;
        }

        if (newErrors.passwordErrors.length > 0) {
            isValid = false;
        }

        setErrors(newErrors);
        return isValid;
    }

    const handleChange = (e) => {
        validateForm()
        const { name, value } = e.target;

        setFormData({
            ...formData,
            [name]: value
        });

        if (name === "password") {
            setErrors((prevErrors) => ({
                ...prevErrors,
                passwordErrors: validatePassword(value)
            }));
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (validateForm()) {
            setLoading(true);
            axios.post(`${serverEndpoint}/auth/signup`, {
                email: formData.email,
                password: formData.password,
                business_name: formData.business_name,
                phone: formData.phone
            }, { withCredentials: true })
                .then(response => {
                    setVerifyEmailDetails({
                        email: formData.email,
                        businessName: formData.business_name,
                        phone: formData.phone,
                    });
                    setLoading(false);
                }).catch(error => {
                    console.log(error);
                    if (error.response.data.error && error.response.data.error === 'User already exists') {
                        setErrors({ ...errors, message: "An account already exists with the provided email." });
                    }
                    setLoading(false);
                });
        }
    };

    if (verifyEmailDetails) {
        return <VerifyEmail userDetails={verifyEmailDetails} />
    }

    return (
        <section className="h-100">
            <div className="container h-100">
                <div className="row justify-content-sm-center h-100">
                    <div className="col-xxl-4 col-xl-5 col-lg-5 col-md-7 col-sm-9">
                        <div className="card shadow-lg">
                            <div className="text-center mt-2">
                                <TbHexagonLetterJ size={90} className="text-primary" />
                            </div>
                            <div className="card-body p-4">
                                <h1 className="fs-4 card-title fw-bold mb-4">Sign up with a new account</h1>
                                {errors.message && (
                                    <div className="alert alert-danger" role="alert">
                                        {errors.message}
                                    </div>
                                )}
                                <form onSubmit={handleSubmit}>
                                    <div className="mb-3">
                                        <label className="mb-2 text-muted" htmlFor="business_name">
                                            Business Name
                                        </label>
                                        <input id="business_name" type="text" className={errors.business_name ? 'form-control is-invalid' : 'form-control'}
                                            name="business_name" autoFocus onChange={handleChange} value={formData.business_name}
                                        />
                                        <div className="invalid-feedback">{errors.business_name}</div>
                                    </div>
                                    <div className="mb-3">
                                        <label className="mb-2 text-muted" htmlFor="email">
                                            Email
                                        </label>
                                        <input id="email" type="text" className={errors.email ? 'form-control is-invalid' : 'form-control'}
                                            name="email" placeholder="name@host.com" onChange={handleChange}
                                            value={formData.email}
                                        />
                                        <div className="invalid-feedback">{errors.email}</div>
                                    </div>
                                    <div className="mb-3">
                                        <label className="mb-2 text-muted" htmlFor="phone">
                                            Phone
                                        </label>
                                        <input id="phone" type="text" name="phone" placeholder="+440123456789"
                                            className={errors.phone ? 'form-control is-invalid' : 'form-control'}
                                            onChange={handleChange} value={formData.phone}
                                        />
                                        <div className="invalid-feedback">{errors.phone}</div>
                                    </div>
                                    <div className="mb-3">
                                        <label className="mb-2 text-muted" htmlFor="password">
                                            Password
                                        </label>
                                        <input id="password" type="password" className={errors.passwordErrors.length > 0 ? 'form-control is-invalid' : 'form-control'}
                                            name="password" onChange={handleChange} value={formData.password}
                                        />
                                        {errors.passwordErrors.length > 0 && (
                                            <div className="invalid-feedback">
                                                <ul>
                                                    {errors.passwordErrors.map((error, index) => (
                                                        <li key={index}>{error}</li>
                                                    ))}
                                                </ul>
                                            </div>
                                        )}
                                    </div>

                                    {/* Submit Button */}
                                    <div className="align-items-center d-flex">
                                        {loading ? (
                                            <button className="btn btn-primary ms-auto" type="button" disabled>
                                                <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true" />
                                            </button>
                                        ) : (
                                            <button className="btn btn-primary ms-auto" type="submit">Register</button>
                                        )}
                                    </div>
                                </form>
                            </div>
                            <div className="card-footer py-3 border-0">
                                <div className="text-center">
                                    Already have an account?{" "}
                                    <Link to="/signin" className="text-dark">Sign in</Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default Signup;
