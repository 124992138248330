import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
    CCloseButton, CSidebar, CSidebarBrand, CSidebarHeader,
    CSidebarNav, CNavItem, CNavLink
} from '@coreui/react';
import { TbHexagonLetterJ } from "react-icons/tb";
import { NavLink } from 'react-router-dom';
import { MdOutlineScreenSearchDesktop, MdOutlineAddToQueue } from "react-icons/md";

function AppSidebar() {
    const dispatch = useDispatch()
    const unfoldable = useSelector((state) => state.sidebarUnfoldable)
    const sidebarShow = useSelector((state) => state.sidebarShow)

    return (
        <CSidebar
            className="border-end"
            colorScheme="dark"
            position="fixed"
            unfoldable={unfoldable}
            visible={sidebarShow}
            onVisibleChange={(visible) => {
                dispatch({ type: 'set', sidebarShow: visible })
            }}
        >
            <CSidebarHeader className="border-bottom">
                <CSidebarBrand className='dropdown-item' as={NavLink} to="/">
                    <TbHexagonLetterJ size={24} className="mb-1 mx-1" />
                    Storefront Stalker
                </CSidebarBrand>
                <CCloseButton
                    className="d-lg-none"
                    dark
                    onClick={() => dispatch({ type: 'set', sidebarShow: false })}
                />
            </CSidebarHeader>
            <CSidebarNav>
                <CNavItem>
                    <CNavLink to="/storefront-search" as={NavLink}>
                        <MdOutlineScreenSearchDesktop size={20} className='me-2' /> Search Storefront
                    </CNavLink>
                </CNavItem>
                <CNavItem>
                    <CNavLink to="/storefront-manager" as={NavLink}>
                        <MdOutlineAddToQueue size={20} className='me-2' /> Add Storefront
                    </CNavLink>
                </CNavItem>
            </CSidebarNav>
        </CSidebar>
    )
}

export default AppSidebar;
