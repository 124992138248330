import { useState } from "react";
import { FaUserCheck } from "react-icons/fa";
import axios from 'axios';
import { serverEndpoint } from "../../util/config";
import triggerVerifyEmail from "../../util/triggerVerifyEmail";
import PaymentPlans from "../payments/PaymentPlans";

function VerifyEmail({ userDetails }) {
    const [loading, setLoading] = useState(false);
    const [verificationCode, setVerificationCode] = useState('');
    const [message, setMessage] = useState(null);
    const [successMessage, setSuccessMessage] = useState(null);
    const [redirectToPayment, setRedirectToPayment] = useState(false);

    const handleChange = (e) => {
        const inputValue = e.target.value;

        // Allow only numbers and limit length to 6
        if (/^\d*$/.test(inputValue) && inputValue.length <= 6) {
            setVerificationCode(inputValue);
        }
    };

    const handleSubmit = async (e) => {
        setLoading(true);
        setMessage(null);
        setSuccessMessage(null);
        e.preventDefault();
        try {
            await axios.post(`${serverEndpoint}/auth/verify-email`, {
                email: userDetails.email,
                code: verificationCode
            }, { withCredentials: true });
            setRedirectToPayment(true);
        } catch (error) {
            console.log(error);
            if (error.response?.data?.error && error.response.data.error === 'Invalid verification code provided, please try again.') {
                setMessage("Authentication failed, Invalid Code");
            } else {
                setMessage("Unable to verify your email. Please log in and verify from your Account page.");
            }
            setLoading(false);
        }
    };

    const handleResendVerificationCode = async () => {
        setLoading(true);
        setMessage(null);
        setSuccessMessage(null);
        await triggerVerifyEmail(userDetails.email);
        setSuccessMessage("Verification code has been resent.");
        setLoading(false);
    };

    if (redirectToPayment) {
        return <PaymentPlans userDetails={userDetails} />;
    }

    return (
        <div className="row justify-content-center mt-7">
            <div className="col-lg-5 text-center">
                <div className="card mt-5">
                    <div className="card-body py-5 px-lg-5">
                        <div>
                            {message && (
                                <div className="alert alert-danger" role="alert">
                                    {message}
                                </div>
                            )}

                            {successMessage && (
                                <div className="alert alert-success" role="alert">
                                    {successMessage}
                                </div>
                            )}
                        </div>

                        <FaUserCheck size={32} />
                        <h3 className="fw-normal text-dark mt-4">Confirm Your Account</h3>
                        <p className="mt-4 mb-1">
                            We have sent a code by email to {userDetails.email}. Enter it below to confirm your account.
                        </p>
                        <form onSubmit={handleSubmit}>
                            <div className="row mt-4 pt-2">
                                <div className="col">
                                    <input
                                        type="text"
                                        className="form-control text-center py-2"
                                        maxLength={6}
                                        minLength={6}
                                        placeholder="XXXXXX"
                                        autoFocus
                                        value={verificationCode}
                                        onChange={handleChange}
                                    />
                                </div>
                            </div>
                            {loading && (
                                <button className="btn btn-success btn-lg w-100 mt-4" type="button" disabled="">
                                    <span
                                        className="spinner-border spinner-border-sm"
                                        role="status"
                                        aria-hidden="true"
                                    />
                                </button>
                            )}

                            {!loading && (
                                <button className="btn btn-success btn-lg w-100 mt-4" type="submit">
                                    Verify
                                </button>
                            )}
                        </form>

                    </div>
                    <div className="card-footer py-3 border-0">
                        <div className="text-center">
                            Didn't receive a code?
                            <button onClick={handleResendVerificationCode} className="btn btn-link text-dark mb-1">
                                Send a new code
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default VerifyEmail;